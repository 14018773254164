const mapTabs = [...document.querySelectorAll(`.map__btn`)];
const mapContent = [...document.querySelectorAll(`.map__content`)];

const madeTabs = (tabs, content) => {
  tabs.forEach((item, index) => {
    item.addEventListener(`click`, (event) => {
      event.preventDefault();

      if (content[index].classList.contains(`active`)) {
        item.classList.remove(`active`);
        content[index].classList.remove(`active`);
      } else {
        tabs.forEach((i, it) => {
          i.classList.remove(`active`);
          content[it].classList.remove(`active`);
        });

        item.classList.add(`active`);
        content[index].classList.add(`active`);
      }
    });
  });
};

if (mapTabs) {
  mapTabs.forEach((item, index) => {
    item.addEventListener(`click`, (event) => {
      event.preventDefault();

      if (!mapContent[index].classList.contains(`active`)) {
        mapTabs.forEach((i, it) => {
          i.classList.remove(`active`);
          mapContent[it].classList.remove(`active`);
        });

        item.classList.add(`active`);
        mapContent[index].classList.add(`active`);
      }
    });
  });
}

const appAreaItem = [...document.querySelectorAll(`.app-area__caption`)];
const appAreaItemContent = [...document.querySelectorAll(`.app-area__sublist`)];

if (appAreaItem) {
  madeTabs(appAreaItem, appAreaItemContent);
}

const appAreaSubItem1 = [...document.querySelectorAll(`.app-area__item.chemistry .app-area__subitem span`)];
const appAreaSubItemContent1 = [...document.querySelectorAll(`.app-area__item.chemistry .app-area__wrapper`)];

if (appAreaSubItem1) {
  madeTabs(appAreaSubItem1, appAreaSubItemContent1);
}

const appAreaSubItem2 = [...document.querySelectorAll(`.app-area__item.oil .app-area__subitem span`)];
const appAreaSubItemContent2 = [...document.querySelectorAll(`.app-area__item.oil .app-area__wrapper`)];

if (appAreaSubItem2) {
  madeTabs(appAreaSubItem2, appAreaSubItemContent2);
}

const appAreaSubItem3 = [...document.querySelectorAll(`.app-area__item.logistics .app-area__subitem span`)];
const appAreaSubItemContent3 = [...document.querySelectorAll(`.app-area__item.logistics .app-area__wrapper`)];

if (appAreaSubItem3) {
  madeTabs(appAreaSubItem3, appAreaSubItemContent3);
}

const appAreaSubItem4 = [...document.querySelectorAll(`.app-area__item.citizens .app-area__subitem span`)];
const appAreaSubItemContent4 = [...document.querySelectorAll(`.app-area__item.citizens .app-area__wrapper`)];

if (appAreaSubItem4) {
  madeTabs(appAreaSubItem4, appAreaSubItemContent4);
}

const tableBtn = [...document.querySelectorAll(`.tech-tables__subtitle`)];
const tableContent = [...document.querySelectorAll(`.tech-tables__content`)];

if (tableBtn) {
  madeTabs(tableBtn, tableContent);
}

const stepsBtn = [...document.querySelectorAll(`.steps__btn-tabs`)];
const stepsContent = [...document.querySelectorAll(`.steps__contents-tabs`)];

if (stepsBtn) {
  madeTabs(stepsBtn, stepsContent);
}
