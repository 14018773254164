const openMenu = document.querySelector(`.header__toggle-menu`);
const menu = document.querySelector(`.nav`);
const overlay = document.querySelector(`.overlay`);
const submenuOpen = [...document.querySelectorAll(`.nav__link--submenu`)];

if (openMenu) {
  openMenu.addEventListener(`click`, () => {
    openMenu.classList.toggle(`toggle`);
    menu.classList.toggle(`show`);
    overlay.classList.toggle(`show`);

    if (!menu.classList.contains(`show`)) {
      for (const it of submenuOpen) {
        it.classList.remove(`open`);
      }
    }
  });
}

if (window.innerWidth < 1170) {
  if (submenuOpen) {
    submenuOpen.forEach((item) => {
      item.addEventListener(`click`, (event) => {
        event.preventDefault();

        if (item.classList.contains(`open`)) {
          item.classList.remove(`open`);
        } else {
          for (const it of submenuOpen) {
            it.classList.remove(`open`);
          }

          item.classList.add(`open`);
        }

      });
    });
  }
}
