const popupCostOpen = [...document.querySelectorAll(`.cost`)];
const popupOrderOpen = [...document.querySelectorAll(`.order`)];
const overlay = document.querySelector(`.overlay`);

if (popupCostOpen.length) {
  const popupCost = document.getElementById(`cost`);
  const popupCostClose = popupCost.querySelector(`.popup__btn-close`);

  for (const it of popupCostOpen) {
    it.addEventListener(`click`, () => {
      overlay.classList.add(`show-popup`);
      popupCost.classList.add(`show`);
    });
  }

  overlay.addEventListener(`click`, () => {
    overlay.classList.remove(`show-popup`);
    popupCost.classList.remove(`show`);
  });

  popupCostClose.addEventListener(`click`, () => {
    overlay.classList.remove(`show-popup`);
    popupCost.classList.remove(`show`);
  });

  window.addEventListener(`keydown`, (event) => {
    if (event.keyCode === 27) {
      if (popupCost.classList.contains(`show`)) {
        overlay.classList.remove(`show-popup`);
        popupCost.classList.remove(`show`);
      }
    }
  });
}

if (popupOrderOpen.length) {
  const popupOrder = document.getElementById(`order`);
  const popupOrderClose = popupOrder.querySelector(`.popup__btn-close`);

  for (const it of popupOrderOpen) {
    it.addEventListener(`click`, () => {
      overlay.classList.add(`show-popup`);
      popupOrder.classList.add(`show`);
    });
  }

  overlay.addEventListener(`click`, () => {
    overlay.classList.remove(`show-popup`);
    popupOrder.classList.remove(`show`);
  });

  popupOrderClose.addEventListener(`click`, () => {
    overlay.classList.remove(`show-popup`);
    popupOrder.classList.remove(`show`);
  });

  window.addEventListener(`keydown`, (event) => {
    if (event.keyCode === 27) {
      if (popupOrder.classList.contains(`show`)) {
        overlay.classList.remove(`show-popup`);
        popupOrder.classList.remove(`show`);
      }
    }
  });
}
