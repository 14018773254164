const header = document.querySelector(`.header`);

let width;

if (document.documentElement.clientWidth < 768) {
  width = 546;
} else if (document.documentElement.clientWidth < 1200) {
  width = 415;
} else {
  width = document.documentElement.clientHeight - 134;
}

let scroll = window.pageYOffset;

if (scroll >= width) {
  header.classList.add(`header-white`);
} else {
  header.classList.remove(`header-white`);
}

window.onscroll = () => {
  scroll = window.pageYOffset;

  if (scroll >= width) {
    header.classList.add(`header-white`);
  } else {
    header.classList.remove(`header-white`);
  }
};
