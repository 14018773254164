const btnMapShow = [...document.querySelectorAll(`.show-coord`)];
const map = document.getElementById(`map`);
const map2 = document.getElementById(`map2`);
const coord = {
  'All': [[55.649918, 37.539591], [59.908638, 30.322031], [56.842758, 60.605425], [55.779180, 49.113825], [55.168773, 61.406551], [54.733021, 55.951172], [48.702685, 44.498021], [66.094574, 76.678630], [61.265819, 73.382513], [57.135376, 65.557298], [56.321067, 44.009490]],
  'Moscow': [55.649918, 37.539591],
  'St. Petersburg': [59.908638, 30.322031],
  'Ekaterinburg': [56.842758, 60.605425],
  'Kazan': [55.779180, 49.113825],
  'Chelyabinsk': [55.168773, 61.406551],
  'Ufa': [54.733021, 55.951172],
  'Volgograd': [48.702685, 44.498021],
  'New Urengoy': [66.094574, 76.678630],
  'Surgut': [61.265819, 73.382513],
  'Tyumen': [57.135376, 65.557298],
  'Nizhny-Novgorod': [56.321067, 44.009490],
};

if (map) {
  ymaps.ready(() => init(map, btnMapShow));
}

if (map2) {
  ymaps.ready(() => init(map2, btnMapShow));
}

const btnOfficeMap = document.querySelector(`.contacts__tabs .office`);
const btnProdMap = document.querySelector(`.contacts__tabs .prod`);
const btnStockMap = document.querySelector(`.contacts__tabs .stock`);

function init(idMap, btnMap = []) {
  let collection = new ymaps.GeoObjectCollection(null);

  for (const city of coord.All) {
    collection.add(new ymaps.Placemark(city, {}, {
      iconLayout: `default#image`,
      iconImageHref: `../images/svg/pin.svg`,
      iconImageSize: [30, 42],
      iconImageOffset: [-5, -38],
    }));
  }

  let myMap = new ymaps.Map(idMap, {
    center: [57.426448, 51.690651],
    zoom: 3
  }, {
    searchControlProvider: `yandex#search`
  });

  myMap.geoObjects.add(collection);
  myMap.behaviors.disable(`scrollZoom`);

  for (const btn of btnMap) {
    btn.addEventListener(`click`, (event) => {
      event.preventDefault();
      myMap.destroy();
      myMap = null;

      if (btn.getAttribute(`id`) === `All`) {
        myMap = new ymaps.Map(idMap, {
          center: [57.426448, 51.690651],
          zoom: 3
        }, {
          searchControlProvider: `yandex#search`,
        });

        myMap.geoObjects.add(collection);
        myMap.behaviors.disable(`scrollZoom`);
      } else {
        myMap = new ymaps.Map(idMap, {
          center: coord[btn.getAttribute(`id`)],
          zoom: 17
        }, {
          searchControlProvider: `yandex#search`
        });

        let myGeoObject = new ymaps.Placemark(coord[btn.getAttribute(`id`)], {}, {
          iconLayout: `default#image`,
          iconImageHref: `../images/svg/pin.svg`,
          iconImageSize: [30, 42],
          iconImageOffset: [-15, -38],
        });

        myMap.geoObjects.add(myGeoObject);
        myMap.behaviors.disable(`scrollZoom`);
      }
    });
  }

  if (btnOfficeMap) {
    btnOfficeMap.addEventListener(`click`, () => {
      myMap.destroy();
      myMap = null;

      myMap = new ymaps.Map(idMap, {
        center: [57.426448, 51.690651],
        zoom: 3
      }, {
        searchControlProvider: `yandex#search`
      });

      myMap.geoObjects.add(collection);
      myMap.behaviors.disable(`scrollZoom`);
    });
  }

  if (btnProdMap) {
    btnProdMap.addEventListener(`click`, () => {
      myMap.destroy();
      myMap = null;

      myMap = new ymaps.Map(idMap, {
        center: [55.246236, 36.663432],
        zoom: 15
      }, {
        searchControlProvider: `yandex#search`
      });

      let myGeoObject = new ymaps.Placemark([55.246236, 36.663432], {}, {
        iconLayout: `default#image`,
        iconImageHref: `../images/svg/pin.svg`,
        iconImageSize: [30, 42],
        iconImageOffset: [-5, -38],
      });

      myMap.geoObjects.add(myGeoObject);
      myMap.behaviors.disable(`scrollZoom`);
    });
  }

  if (btnStockMap) {
    btnStockMap.addEventListener(`click`, () => {
      myMap.destroy();
      myMap = null;

      myMap = new ymaps.Map(idMap, {
        center: [55.614040, 37.484855],
        zoom: 17
      }, {
        searchControlProvider: `yandex#search`
      });

      let myGeoObject = new ymaps.Placemark([55.614040, 37.484855], {}, {
        iconLayout: `default#image`,
        iconImageHref: `../images/svg/pin.svg`,
        iconImageSize: [30, 42],
        iconImageOffset: [-5, -38],
      });

      myMap.geoObjects.add(myGeoObject);
      myMap.behaviors.disable(`scrollZoom`);
    });
  }
}

const contactsBtns = [...document.querySelectorAll(`.contacts__btn`)];
const contactsContent = [...document.querySelectorAll(`.contacts__content`)];
const contactsBtnShowMap = document.querySelector(`.contacts__buttons-show-map`);

if (contactsBtns) {
  contactsBtns.forEach((item, index) => {
    item.addEventListener(`click`, (event) => {
      event.preventDefault();
      contactsBtns.forEach((i, it) => {
        i.classList.remove(`active`);
        contactsContent[it].classList.remove(`active`);
      });

      if (item.textContent === `Офис`) {
        contactsBtnShowMap.style.display = `grid`;
      } else {
        contactsBtnShowMap.style.display = `none`;
      }

      item.classList.add(`active`);
      contactsContent[index].classList.add(`active`);
    });
  });
}
