const select = document.querySelectorAll(`.select__wrapper`);
const selectCheck = document.querySelectorAll(`.select__selected`);

if (selectCheck) {
  [...selectCheck].forEach((it, index) => {
    it.addEventListener(`click`, () => {
      if (it.classList.contains(`show`)) {
        it.classList.remove(`show`);
        select[index].classList.remove(`show`);
      } else {
        for (const item of [...select]) {
          item.classList.remove(`show`);
        }

        for (const item of [...selectCheck]) {
          item.classList.remove(`show`);
        }

        it.classList.add(`show`);
        setTimeout(() => {
          select[index].classList.add(`show`);
        }, 10);
      }

    });
  });

  [...select].forEach((it, index) => {
    const checkList = it.querySelectorAll(`li`);

    [...checkList].forEach((item) => {
      item.addEventListener(`click`, () => {
        for (const iter of [...checkList]) {
          iter.classList.remove(`active`);
        }

        item.classList.add(`active`);
        selectCheck[index].children[0].innerHTML = item.textContent;
        selectCheck[index].classList.remove(`show`);
        it.classList.remove(`show`);
      });
    });
  });

  window.addEventListener(`keydown`, (event) => {
    if (event.keyCode === 27) {
      select.forEach((it, index) => {
        if (it.classList.contains(`show`)) {
          it.classList.remove(`show`);
          selectCheck[index].classList.remove(`show`);
        }
      });
    }
  });

  selectCheck.forEach((it, index) => {
    it.addEventListener(`blur`, () => {
      it.classList.remove(`show`);
      select[index].classList.remove(`show`);
    });
  });
}
