import Glide from '@glidejs/glide';

const sliderHeader = document.querySelector(`.slider__slides`);

if (sliderHeader) {
  new Glide(sliderHeader, {
    type: `carousel`,
    gap: 0,
    autoplay: 5000,
  }).mount();
}

const sliderProducts = document.querySelector(`.products__slides`);

if (sliderProducts) {
  new Glide(sliderProducts, {
    type: `carousel`,
    perView: 3,
    breakpoints: {
      1280: {
        perView: 2,
      },
      768: {
        perView: 2,
      },
      500: {
        perView: 1,
      }
    },
  }).mount();
}

const companySlider = document.querySelector(`.company__slider`);

if (companySlider) {
  new Glide(companySlider, {
    type: `carousel`,
    perView: 9,
    gap: 30,
    breakpoints: {
      1700: {
        perView: 7,
      },
      1280: {
        perView: 4,
      },
      500: {
        perView: 2,
      }
    }
  }).mount();
}

const docSlider = document.querySelector(`.doc__slider`);

if (docSlider) {
  new Glide(docSlider, {
    type: `carousel`,
  }).mount();
}

const stepsSlider = document.querySelector(`.steps__slides`);

if (stepsSlider) {
  new Glide(stepsSlider, {
    type: `carousel`,
    perView: 4,
    gap: 20,
    breakpoints: {
      1280: {
        perView: 2,
      },
      500: {
        perView: 1,
      }
    }
  }).mount();
}
