const truncate = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength - 3)}...`;
  }
  return str;
};

const newsText = [...document.querySelectorAll(`.news__short-desc`)];

if (newsText) {
  for (const text of newsText) {
    text.innerHTML = truncate(text.textContent, 200);
  }
}

const serviceFAQ = [...document.querySelectorAll(`.service__wrapper`)];
const btnShowServiceFAQ = [...document.querySelectorAll(`.service__show-more`)];

if (btnShowServiceFAQ) {
  btnShowServiceFAQ.forEach((it, index) => {
    it.addEventListener(`click`, (event) => {
      event.preventDefault();

      if (!serviceFAQ[index].classList.contains(`show`)) {
        for (const faq of serviceFAQ) {
          faq.classList.remove(`show`);
        }

        serviceFAQ[index].classList.add(`show`);
      } else {
        serviceFAQ[index].classList.remove(`show`);
      }
    });
  });
}
