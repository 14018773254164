const input = document.getElementById(`file`);
const name = document.querySelector(`.form__upload-file label`);

const updateImageDisplay = () => {
  const curFiles = input.files[0];
  name.innerHTML = curFiles.name;
};

if (input) {
  input.addEventListener(`change`, () => {
    updateImageDisplay();
  });
}
