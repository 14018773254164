const specialOffersText = [...document.querySelectorAll(`.special-offers__wrapper`)];
const specialOffersBtn = [...document.querySelectorAll(`.special-offers__show-btn`)];

specialOffersBtn.forEach((it, index) => {
  it.addEventListener(`click`, (event) => {
    event.preventDefault();

    if (it.classList.contains(`open`)) {
      it.classList.remove(`open`);
      specialOffersText[index].classList.remove(`open`);
    } else {
      for (const btn of specialOffersBtn) {
        btn.classList.remove(`open`);
      }

      for (const text of specialOffersText) {
        text.classList.remove(`open`);
      }

      it.classList.add(`open`);
      specialOffersText[index].classList.add(`open`);
    }
  });
});
