const btn = document.querySelector(`.footer__btn-scroll-up`);
const sliderBtn = document.querySelector(`.slider__btn-scroll`);
const content = document.getElementById(`content`);

let scrolled;
let timer;

const scrollToTop = () => {
  if (scrolled > 0) {
    window.scrollTo(0, scrolled);
    scrolled -= 80;

    timer = setTimeout(requestAnimationFrame(scrollToTop), 10);
  } else {
    clearTimeout(timer);
    window.scrollTo(0, 0);
  }
};

let time;

const scrollToElem = () => {
  if (content.getBoundingClientRect().top > 80) {
    window.scrollBy(0, 30);
    time = setTimeout(requestAnimationFrame(scrollToElem), 10);
  } else {
    clearTimeout(time);
  }
};

if (btn) {
  btn.addEventListener(`click`, (event) => {
    event.preventDefault();
    scrolled = window.pageYOffset;
    requestAnimationFrame(scrollToTop);
  });
}

if (sliderBtn) {
  sliderBtn.addEventListener(`click`, (event) => {
    event.preventDefault();
    requestAnimationFrame(scrollToElem);
  });
}

const map = document.querySelector(`.contacts__map`);
const btnToMap = [...document.querySelectorAll(`.contacts__button-block .show-coord`)];

if (btnToMap) {
  const scrollToMap = () => {
    if (map.getBoundingClientRect().top < 100) {
      window.scrollBy(0, -30);
      requestAnimationFrame(scrollToMap);
    }
  };

  for (const btnItem of btnToMap) {
    btnItem.addEventListener(`click`, () => {
      requestAnimationFrame(scrollToMap);
    });
  }
}

const techTables = [...document.querySelectorAll(`.tech-tables__subtitle`)];

if (techTables) {
  const scrollToTable = (it) => {
    it.scrollIntoView();
    window.scrollBy(0, -155);
  };

  for (const btnTable of techTables) {
    btnTable.addEventListener(`click`, () => {
      requestAnimationFrame(() => scrollToTable(btnTable));
    });
  }
}
